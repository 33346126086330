import React, { useState, useEffect } from 'react'
import "./Items.css"

const EventItem = (props) => {
    //props.key props.caption, props.route, props.image, props.ChangeView

    const ChangeView = (route) => {
        props.ChangeView(route)
    }

    return(
        <li className='li-item-short' onClick={ () => ChangeView(props.route)}>           
            <div className='item-short'>
                <h2>{props.caption}</h2>
                <img src={props.image} alt='Image' />
            </div>
        </li>    
           )
}
export default EventItem