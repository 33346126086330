import React, { useState, useEffect } from 'react'
import "./Header.css"
import HeaderItem from './HeaderItem'

const Header = (props) => {
    return(
            <div className='header'>
                <div className='header-items-container'>
                <HeaderItem caption={"Båt hos oss"} size={"small"} route={"hem"} ChangeView={props.ChangeView} />
                <HeaderItem caption={"Om JSS"} size={"small"} route={"hem"} ChangeView={props.ChangeView} />
                <HeaderItem caption={"Hem"} size={"large"} route={"hem"} ChangeView={props.ChangeView} />
                <HeaderItem caption={"Styrelsen"} size={"small"} route={"hem"} ChangeView={props.ChangeView} />
                <HeaderItem caption={"Logga in"} size={"small"} route={"login"} ChangeView={props.ChangeView} />
                </div>
            </div>
           )
}
export default Header