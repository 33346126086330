import { child, set } from "firebase/database";

let text = "this is the default text"

let dbGet = new (Object);
let dbSet = new (Object);
let dbChild = new (Object);
let dbRef = new (Object);

const routeNews = process.env.REACT_APP_ROUTE_NEWS
const routeEvents = process.env.REACT_APP_ROUTE_EVENTS
const routePages = process.env.REACT_APP_ROUTE_PAGES
const routeSchemas = process.env.REACT_APP_ROUTE_SCHEMAS
const routeSchemasSjosattningCrane = process.env.REACT_APP_ROUTE_SCHEMAS_SJOSATTNING_CRANE
const routeSchemasSjosattningTruck = process.env.REACT_APP_ROUTE_SCHEMAS_SJOSATTNING_TRUCK
const routeSchemasUpptagningCrane = process.env.REACT_APP_ROUTE_SCHEMAS_UPPTAGNING_CRANE
const routeSchemasUpptagningTruck = process.env.REACT_APP_ROUTE_SCHEMAS_UPPTAGNING_TRUCK

const routeTestCount = process.env.REACT_APP_ROUTE_TEST_COUNT
const routeTestSpecifiedNews = process.env.REACT_APP_ROUTE_TEST_SPECIFIED_NEWS
const routeItemsMock = process.env.REACT_APP_ROUTE_TEST_ITEMSMOCK

export let isSetup = false

export function ServiceSetup(get, set, child, ref) {
    dbGet = get
    dbSet = set
    dbChild = child
    dbRef = ref

    isSetup = true
    console.log("Is now Setup")
}

export async function CreateNews(newsObject) {
    //newsObject = await createMockObject();

    let count
    //Get and Update news counter
    await dbGet(dbChild(dbRef, routeTestCount)).then((snapshot) => {
        if (snapshot.exists()) {
            count = snapshot.val() + 1
            dbSet(dbChild(dbRef, routeTestCount), count)
        } else {
            console.log("No data available at: test/count")
        }
    }).catch((error) => {
        console.error(error)
    });

    newsObject.page.route = "news_"+count
    newsObject.linkItem.route = "news_"+count

    //Get and Update Item with Links
    await dbGet(dbChild(dbRef, routeItemsMock)).then((snapshot) => {
        if (snapshot.exists()) {
            const newItems = [...snapshot.val(), newsObject.linkItem]
            dbSet(dbChild(dbRef, routeItemsMock), newItems)
        } else {
            console.log("No data available at: test/count")
            dbSet(dbChild(dbRef, routeItemsMock), [newsObject.linkItem]) //----------------------------- THIS IS ONLY WHEN TESTING. Change this to something smart!
        }
    }).catch((error) => {
        console.error(error)
    });

    await dbSet(dbChild(dbRef, routeTestSpecifiedNews + count), newsObject.page)
}

export function ChangeText(newtext) {
    text = newtext
}

export function ReadText() {
    return (text)
}


export function CreateNewsObjectFromPage(page) { 

    //get text data from page
    const foundTextContainer = page.containers.find( container => container.items.find(item => item.type == "text")) //Get pages first container with first found text item here
    const foundTextItem = foundTextContainer.items.find(item => item.type == "text")

    //Create linkItem from pages data
    const linkItem = CreateItem("news", 
    foundTextItem.caption, 
    foundTextItem.content, 
    page.image)
    
    const newsObject = {
        "page": page,
        "linkItem": linkItem
    }
    return newsObject
}

export function CreatePage(imageUrl, containers) {
    const page = {
        "image": imageUrl,
        "route": "SetThisWhenCreatingPage",
        "containers": containers //This needs to be an array
    }
    return page
}

export function CreateContainer(caption, items, listType, style) {
    const container = {
        "caption": caption,
        "items": items, //This needs to be an array
        "listType": listType,
        "style": style
    }
    return container
}

export function CreateItem(_type, caption, content, image) {

    let item

    switch (_type) {
        case "text": {
            item = {
                "caption": caption,
                "content": content,
                "type": _type
            }
            break
        }
        case "event": {
            item = {
                "caption": caption,
                "image": content,
                "route": "event_",
                "type": _type
            }
            break
        }
        case "news": {
            item = {
                "caption": caption,
                "content": content,
                "image": image,
                "route": "news_",
                "type": _type
            }
            break
        }
        case "image-cover": {
            item = {
                "image": image,
                "type": _type
            }
            break
        }
        case "image-half": {
            item = {
                "image": image,
                "type": _type
            }
            break
        }
        default: {
            item = {
                "caption": "Unknow item type created",
                "content": "Unknow item type was created, must be an internal error in the code",
                "type": "text"
            }
            break
        }
    }

    return item
}

//Simulate user creating a news page and item
const createMockObject = async() => {
    const items1 = [
        CreateItem("text",
            "MOCKAD TEXT ITEM",
            "Detta item har jag mockat och skapat med hjälp av mina funktioner",
            null),
        CreateItem("image-cover",
            null,
            null,
            "https://lh3.googleusercontent.com/fife/AAWUweWE9XQJNDRXF-dKfBshXMjyDB6PMf3ejcPAVleQVWbn-j4xxmHwJA1qB9JDKiosuYT3XSiG9VivwC8u96EUSwhpAVgyq4MKoYytpRsagnktt8Vd-S6K-OGskt9AryEWJ08M4e2txccxK4tcMllHpHWpnQBezJBveJ0Bt1F10NPjsvqYx-rbuIRu62UjrHAgA7bJY9kwyhBDG3cv8PZPKxVq_yE4IQ84uIqEjZ7GpKlRvh4CU1SDZ8wFOtryJ3FoMGDcin8n1wYaaec9Bb9zxnlODi2ixHSCzShkAjm_fBbIORmWejQFW4pMb1v9C6HEUm7OZAbhhj8SPoPUPSDwnO6lmNB_b7e0SpOfpXbBJRdGjYyhBYmKDIzM9vnA_QXFEXg1oiPeBv0z6kCY2jjl1kxspfvr0g6K4BjaChYnDEsiDRI_lXlqHHDCiF21_vvAvKgpWUSkqZ0ro2VOP8ZG6z_wLEuMIYs6dM2WpDZUWyEBJdM25A1qXlfbmiQRsn3ttaoThK2LlB1_v4UX-CVNxMuydFwttJXYeMkhkalzWO7ynDufJOzjlqvncxG7boxj6Qt3cFcZKLw8xlzjgKC5qYF4iuYg-HtsqejhlL5efq0Ke9XL0xD4dahcg0Vpd8oKkB2rSJxDlmvcqxcWS07T1svwxJXavl5LuFbiWoczFdsYxp6QzqXVqYqTnl0WIeGIT-n6AycZxRgLDbEhV_gJmv-qw5Nuxyi8TTw=w1246-h721-ft")
            
    ]

    const containers = [
        CreateContainer("Mockad container",
        items1,
        "items",
        "container news")
    ]

    const page = CreatePage("https://lh3.googleusercontent.com/fife/AAWUweXlGiPY1rlVpg77_zVAG8wCEAA8IBS3Pfi7_aGTwnL5zRzWABoK22RtEl0YLrEMqpEOgaWnpVqsSA-6HeW7DCtDxrspJ6CTu-soZGXMkg8U-NU_VPJ1badvtptwJ2Ehaccg9afAihJl1kguf0-Z2BYJ9M_vtLdrUWcoSgfe5fcBFnyT5vE8a_oKVw3yp4ynBm8ON3ab8xLsXpOLhj5mGuWxAtnY-xlLierK7LjGpymOzOvVZZINOIDHanPFxQOtW0etV1Am3azYHYgcNRFuxMnL1V18mZWlD3tpo-BmuRcHo6G8moBayqbBZPrMuzpDp7hr8fC3MjORslogVe-AfFBLQHI6kDdcIDBegyErE0t6-Ur--W0rhtNVN3q_JfGNwIW7UuU_5Wwlhh4t8WBpKDTbUrSdmOOihCHkTqx9Ox7FI4H9mfdm3IhKEFn7-idj-SocYGa2u9CFWcR6RPKZKWy9i6bmlAoMoohqniA-JmRPymgtz_Kixw70tjMrGzAC4B8Id_cVd4l5nsdWA9SOaB0QFGu8LhkPaix1uRVBDd_TjZsu0qYSXyhu5M1q4_WcNompwnAoCY-caHfvAWf471dGlIQ5sUZ5rid3jCUWizBIwp2bOWyaxJ3-NdESfiuAKZW1Zu6BjTzR01b9KKV5ax0RnIznwJ2HcriNXHgCz58_jX3mpY2mukzZVcluGgwvrCiznyVM6cjbFwgvflsjdecEA4b0R-VEVpY=w935-h887-ft",
    containers)

    const foundTextItem = items1.find(item => item.type == "text")

    const linkItem = CreateItem("news", foundTextItem.caption, foundTextItem.content, "https://lh3.googleusercontent.com/fife/AAWUweUk0THKgb_6M37eJVtVXkTOp5N2I2lshG5ZcF6lunOhPDnViYAu2HYR39W4TvRbWs3KuE5UJLvkA2qP8lYkgPnheQp7MAJJeGJZWv7ee86Ej0Qb-RI1EODSjuYwwnJ3uzlrS0H_w2GOGIO-FQfG9bsOYAU66z0bDZ9C95BCyKdQ3rt8TdsX1kcqDuwYGJvKvgqx0pIwTGTyxwvP5N22TCUKT5_NfRvxPIrQU-baJBpuhDHWtwc7JK7aSf8RfIdcPgacS5_ykiZRJE1GHtXeOKXW856gMIdOh08GDLU5Ppd0TMeOt0Zxb4IX3HZgHu0IXb0GIqIbdDBB8I2eh3JIOozQ7hFJRSEldyWTHwSvX8bZYzG7tZMbvszSgzcwicncL_w2xKPJwdweyhXYOwibQjxXlR479ecUmU-NM9qIwXMkUqJAEamzLJoytFI_8f_jzYHmLmsyX8dB8yXaL7A20g0xASWRajFVLyfvmm7Rvz0OTZELdxGcBNYB5qGAT69KrKl_vidtIoFcRTwibEleyX6WgGg2VhtNZuU7BBREx_C6_7pMzz59tW5sig1IVc8yDj2x7SoTQEsLy6PhdWsE5f4oFaSoDNX2Q5hu5fA7V9KGyMboApSZUb-8Z_p_GW-ir5L2K5teMQ6rntfLDzmG1WOkrj1V2jy7zZ3q-onKg3fBYVGtVvHkUSBMFHmQAqVJ9ZdFS4WL2oLe4D0iUHS-TD-5TSUu454nEMA=w1246-h721-ft")
    const newsObject = CreateNewsObjectFromPage(page, linkItem)

    return newsObject
}