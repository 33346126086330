import React, { useState, useEffect } from 'react'


const NewItem = (props) => {    

    const [type, setType] = useState(null)
    const [caption, setCaption] = useState(null)
    const [content, setContent] = useState(null)
    const [image, setImage] = useState(null)

    const changeType = (event) => {
        setType(event.target.value)
    }

    const changeCaption = (event) => {
        setCaption(event.target.value)
    }

    const changeContent = (event) => {
        setContent(event.target.value)
    }

    const changeImage = (event) => {
        setImage(event.target.value)
    }

    useEffect( () => {
        props.updateItem("type", type, props.index)
    }, [type])

    useEffect( () => {
        props.updateItem("caption", caption, props.index)
    }, [caption])

    useEffect( () => {
        props.updateItem("content", content, props.index)
    }, [content])

    useEffect( () => {
        props.updateItem("image", image, props.index)
    }, [image])


    switch(props.type) {
        case "text": {
            return (
                <div className="new-item type-text">
                <input type="text" className="input-text-caption" placeholder='ange rubrik... ' onChange={changeCaption}/>
                <textarea type="text" rows="10" className="input-text-content" placeholder='ange innehåll... ' onChange={changeContent}/>
                </div>
            )
            break
        }
        case "image-cover": {
            return (
                <div className="new-item type-image-cover">
                    <h4>Helbild</h4>
                <input type="text" className="input-text-caption" placeholder='ange URL till bilden... ' onChange={changeImage}/>
                </div>
            )
            break
        }
        case "image-half": {
            return (
                <div className="new-item type-image-half">
                    <h4>Halvbild</h4>
                <input type="text" className="input-text-caption" placeholder='ange URL till bilden... ' onChange={changeImage}/>
                </div>
            )
            break
        }/*<option disabled selected value> -- Välj item typ -- </option>*/
        default : {
            return(
                <select onChange={changeType} className="item-type" defaultValue="default">
                    <option disabled value="default"> -- Välj item typ -- </option>
                    <option value="text">text</option>
                    <option value="image-cover">helbild</option>
                    <option value="image-half">2 halv-bilder</option>
                </select>
            )
        }
    }
    
}
export default NewItem