import React, { useState, useEffect } from 'react'

const AdminItem = (props) => {
    //

    const ChangeRoute = (route) => {
        props.ChangeRoute(route)
    }

    if(props.active) {
        return(
            <li className='li-admin-item-short' onClick={ () => ChangeRoute(props.route)}>           
                <div className='admin-item-short'>
                    <h2>{props.caption}</h2>
                </div>
            </li>    
               )
    } else {
        return(
            <li className='li-admin-item-short inactive' >           
                <div className='admin-item-short inactive'>
                    <h2 className='inactive'>{props.caption}</h2>
                </div>
            </li>    
               )
    }
    
}
export default AdminItem