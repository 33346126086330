import React, { useState, useEffect } from 'react'
import "./Header.css"

const HeaderItem = (props) => {

    const Navigate = () => {
        props.ChangeView(props.route)
    }

    if (props.admin == null) {
        return (
            <div className={props.size == "large" ? "header-border-item-large" : "header-border-item"} >
                <div className={props.size == "large" ? "header-item-large" : "header-item"} onClick={Navigate}>
                    {props.caption}
                </div>
            </div>
        )
    } else { //if its a AdminHeaderItem
        return (
            <div className={props.size == "large" ? "admin-header-item-large" : "admin-header-item"} onClick={Navigate}>
                {props.caption}
            </div>
        )
    }


}
export default HeaderItem