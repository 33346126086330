import React, { useState, useEffect } from 'react'
import './Items.css'

const NewsItem = (props) => {
    //props.caption, props.content, props.route, props.image, props.ChangeView
    
    const ChangeView = (route) => {
        props.ChangeView(route)
    }
    
    return(
        <li className='li-item-long' onClick={ () => ChangeView(props.route)}>        
            <div className='item-long'> 
                <img src={props.image} alt='Image' />
                <h5>{props.caption}</h5>
                <p>{props.content}</p>
            </div>
        </li>
           )
}
export default NewsItem