import React, { useState, useEffect } from 'react'
import "./Header.css"
import HeaderItem from './HeaderItem'

const HeaderLogout = (props) => {
    return(
            <div className='header'>
                <div className='header-items-container header-items-container-one'>
                <HeaderItem caption={"Logga ut"} size={"large"} route={"signout"} ChangeView={props.ChangeView} />
                </div>
            </div>
           )
}
export default HeaderLogout