import React, { useState, useEffect } from 'react'

import Divider from '../../../items/Divider'
import NewContainer from '../create/NewContainer'
import InputNew from '../create/InputNew'

/* -- JSS Services -- */
import { CreateNews,  CreateNewsObjectFromPage, CreatePage} from "../../../../services/JssServices.js"


const AddNews = (props) => {

    let [containers, setContainers] = useState([])

    const newContainer = () => {
        console.log("ADDING NEW CONTAINER")

        setContainers([...containers, {
            "caption": null,
            "items": null,
            "listType": "items",
            "style": "container news"
        }])
    }    

    const updateContainerChanged = (key, value, index) => {

        console.log("updating contaner")
        console.log(key + " " + value)

        let newArr = [...containers]; // copy the old items array
        
        newArr[index][key] = value; // replace value at index

        if(key == "type") {
            //If the type is image-half, add 2 images
            if(value == "image-half") {
                newArr.splice(index, 0, newArr[index]) //adds the new half-image next to the index, not last
                newArr.join()
            }
        }

        setContainers(newArr); //update state with the new array
    }

    const postNews = () => {

        //Activate a posting modal / loader here

        const page = CreatePage("BackgroundImageUrlHere", containers)


        //validate page here

        const newsObject = CreateNewsObjectFromPage(page)

        console.log("newsObject created")
        console.log(newsObject)

        //validate newsObject here ???

        try {
            CreateNews(newsObject)
        } catch (e) {
            //Handle newsobject not created here. make sure that databse is unaffected
            console.log("An error occured while creating a new News Page. Exception: ")
            console.log(e)
        }
    }

    return(
            /* -- page -- */
        <div className='main' id="page" style={{ backgroundImage: `url('https://lh3.googleusercontent.com/fife/AAWUweXlGiPY1rlVpg77_zVAG8wCEAA8IBS3Pfi7_aGTwnL5zRzWABoK22RtEl0YLrEMqpEOgaWnpVqsSA-6HeW7DCtDxrspJ6CTu-soZGXMkg8U-NU_VPJ1badvtptwJ2Ehaccg9afAihJl1kguf0-Z2BYJ9M_vtLdrUWcoSgfe5fcBFnyT5vE8a_oKVw3yp4ynBm8ON3ab8xLsXpOLhj5mGuWxAtnY-xlLierK7LjGpymOzOvVZZINOIDHanPFxQOtW0etV1Am3azYHYgcNRFuxMnL1V18mZWlD3tpo-BmuRcHo6G8moBayqbBZPrMuzpDp7hr8fC3MjORslogVe-AfFBLQHI6kDdcIDBegyErE0t6-Ur--W0rhtNVN3q_JfGNwIW7UuU_5Wwlhh4t8WBpKDTbUrSdmOOihCHkTqx9Ox7FI4H9mfdm3IhKEFn7-idj-SocYGa2u9CFWcR6RPKZKWy9i6bmlAoMoohqniA-JmRPymgtz_Kixw70tjMrGzAC4B8Id_cVd4l5nsdWA9SOaB0QFGu8LhkPaix1uRVBDd_TjZsu0qYSXyhu5M1q4_WcNompwnAoCY-caHfvAWf471dGlIQ5sUZ5rid3jCUWizBIwp2bOWyaxJ3-NdESfiuAKZW1Zu6BjTzR01b9KKV5ax0RnIznwJ2HcriNXHgCz58_jX3mpY2mukzZVcluGgwvrCiznyVM6cjbFwgvflsjdecEA4b0R-VEVpY=w935-h887-ft')` }}>

            <div className="container admin">
                <h1>Lägg till en nyhet</h1>
                <Divider />
                <InputNew type="post" onClick={postNews} />
            </div>
            
            {
                containers.map( (container, index) => {
                    return (
                        <NewContainer key={index} index={index} caption={container.caption} updateContainer={updateContainerChanged}/>
                    )
                })
            }
            
            <InputNew type="container" onClick={newContainer} />

    </div>
    )
    
}
export default AddNews