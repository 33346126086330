import React, { useState, useEffect } from 'react'

import "./Containers.css"
import Divider from '../items/Divider.js'
import TextItem from '../items/TextItem';
import EventItem from '../items/EventItem';
import NewsItem from '../items/NewsItem';
import Schedule from '../items/Schedule';
import ImageItem from '../items/ImageItem';
import SignIn from '../admin/login/SignIn';
import ScheduleItem from "../items/ScheduleItem";

const Container = (props) => {
    //props.caption, props.style, props.items

    const ChangeRoute = (route) => {
        //check if value exist - Funkar denna???
        if(props.container.linked === undefined || null) {
            props.ChangeRoute(route)
        } else {
            console.log(props.container.linked)
            props.ChangeRoute(route, props.container.linked)
        }
        
    }

    return (
        <div className={props.style}>
            <h1>{props.caption}</h1>
            <Divider />
            <ul className={props.listType}>
                {
                    props.items.map((item, index) => { 
                        switch (item.type) {
                            //If the item type is text
                            case "text":
                                return <TextItem key={index} caption={item.caption}
                                    content={item.content}
                                    image={item.image}
                                />
                            //If the item type is image
                            case "image-cover":
                                return <ImageItem key={index}
                                type={item.type}
                                image={item.image}
                                />
                            //If the item type is image
                            case "image-half":
                                return <ImageItem key={index}
                                type={item.type}
                                image={item.image}
                                />
                            //If the item type is event
                            case "event":
                                return <EventItem key={index} caption={item.caption}
                                    content={item.content}
                                    route={item.route}
                                    image={item.image}
                                    ChangeView={ChangeRoute} />
                            //If the item type is news
                            case "news":
                                return <NewsItem key={index} caption={item.caption}
                                    content={item.content}
                                    route={item.route}
                                    image={item.image}
                                    ChangeView={ChangeRoute} />
                            //If the item type is time - DENNA KAN TAS BORT
                            case "time":
                                return <Schedule key={index} caption={item.caption}
                                    type={item.type}
                                />
                            //If the item type is person - DENNA KAN TAS BORT    
                            case "person":
                                return <Schedule key={index} caption={item.caption}
                                    type={item.type}
                                />
                            //If the item type is scheduleItem    
                            case "scheduleItem":
                                return <ScheduleItem key={index} 
                                    time={item.time}
                                    persons={item.persons}
                                />
                            case "login":
                                return <SignIn key={index} auth={props.auth} />
                            default:
                                return <h1 style={{color: "red"}}>Unknown Item Type: {item.type}</h1>;

                        }
                    })
                }
            </ul>
        </div>
    )
}
export default Container