import React, { useState, useEffect } from 'react'
import "../containers/Containers.css"
import "../containers/Schemas.css"
import Divider from '../items/Divider.js'

const Schedule = (props) => {
    if (props.type == "time") {
        return (
            <li className='time'>{props.caption}</li>
        )
    } else {
        return (
            <li className='person'>{props.caption}</li>
        )
    }
}
export default Schedule