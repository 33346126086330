import React, { useState, useEffect, useLayoutEffect } from 'react'

import './App.css';
import Main from "./components/main/Main.js"
import Header from './components/main/Header'
import Page from './components/Page.js'
import SignIn from './components/admin/login/SignIn.js';

import jssPage from './resources/json/jss.json'

import titleLaunching from './resources/json/titleLaunching.json'
import titleMain from './resources/json/titleMain.json'

/* -- JSS Services -- */
import { ChangeText, ReadText, ServiceSetup, CreateNews, isSetup } from "./services/JssServices.js"

/* -- Firebase stuff -- */
/*import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
*/

import { initializeApp } from 'firebase/app';
import { getDatabase, ref, set, child, get } from "firebase/database";

/* -- Auth stuff -- */
import { GoogleAuthProvider, getAuth, signInWithRedirect, getRedirectResult, onAuthStateChanged, signOut } from "firebase/auth";

import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import AdminMain from './components/admin/pages/AdminMain';
import AdminNavigator from './components/admin/AdminNavigator';
import NoAccount from './components/admin/login/NoAccount';
import HeaderLogout from './components/main/HeaderLogout';
import Loading from './components/Loading';

const apiKey = process.env.REACT_APP_API_KEY

/*
firebase.initializeApp({
  apiKey: apiKey,
  authDomain: "jernavikssegelsallskap.firebaseapp.com",
  databaseURL: "https://jernavikssegelsallskap-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "jernavikssegelsallskap",
  storageBucket: "jernavikssegelsallskap.appspot.com",
  messagingSenderId: "1048991422915",
  appId: "1:1048991422915:web:a138591e49472036d28576",
  measurementId: "G-JRVY9X83DW"
});
*/

const firebaseConfig = {
  apiKey: apiKey,
  authDomain: "jernavikssegelsallskap.firebaseapp.com",
  databaseURL: "https://jernavikssegelsallskap-default-rtdb.europe-west1.firebasedatabase.app",
  storageBucket: "jernavikssegelsallskap.appspot.com",
};



function App() {
  const app = initializeApp(firebaseConfig)
  const auth = getAuth(app);
  //signOut(auth)

  const database = getDatabase()
  const dbRef = ref(getDatabase(app))
  /*
  const auth = firebase.auth();
  const firestore = firebase.firestore();

  const [user] = useAuthState(auth);
*/

  const [user, setUser] = useState()
  const [permissions, setPermissions] = useState([])

  const [view, setView] = useState("default")
  const [loaded, setLoaded] = useState(false)

  const [page, setPage] = useState()
  const [pages, setPages] = useState([])

  const [linkedPage, setLinkedPage] = useState()

  const [admin, setAdmin] = useState(false)

  //Run when page loads to check if logged in
  useEffect(() => {
    onAuthStateChanged(auth, authUser => {
      console.log("Auth changed")
      console.log(authUser)
      setUser(authUser)
    })
  }, [])


  
  useEffect(() => {
    //alert("Detta är en demo-sida som är under utveckling. Letar du efter dagens gällande sida så är adressen dit: www.Jernavik.com")
    prompt("Detta är en demo-sida som är under utveckling. Letar du efter dagens gällande sida så är adressen dit:", "www.jernavik.com");
  }, [])

  /*
  //Testing modules and create news
  useEffect(() => {
    setupServices()

    //Idea for this: Run setup, but in JSSServices only do so if it isn't already setup.
    //OR: when runnin CreateNews, run a setup if it hasn't already been setup

    if(isSetup) {
      CreateNews()
    }
  }, [])
  */

  //fetch pages from firebase db
  useEffect(() => {
    get(child(dbRef, `pages/`)).then((snapshot) => {
      if (snapshot.exists()) {
        setPages(snapshot.val())
      } else {
        console.log("No data available at: pages/")
      }
    }).catch((error) => {
      console.error(error)
    });
  }, [])

  //Fetch a specified linked page
  const GetSetLinkedPage = (route, link) => {
    get(child(dbRef, link+`/`+route)).then((snapshot) => {
      if (snapshot.exists()) {
        //setLinkedPage(snapshot.val().find(_page => _page.route == route))
        setLinkedPage(snapshot.val())
      } else {
        console.log("No data available at: " + link+`/`+route)
      }
    }).catch((error) => {
      console.error(error)
    });
  }

  //When a linked page is received
  useEffect(() => {
    if (linkedPage != null && linkedPage != undefined) {
      console.log("changin to a linked page")
      setPage(linkedPage)
    } else {
      console.log("linkedpage didnt pass validation: ")
      console.log(linkedPage)
    }
  }, [linkedPage])

  //When pages are received
  useEffect(() => {
    if (pages != null) {
      ChangeRoute("hem")
    }
  }, [pages])

  //When User state has changed (user logged in / out)
  useEffect(() => {
    //if signed in
    if (user != null) {
      get(child(dbRef, `users/`+ user.uid)).then((snapshot) => {
        //If user is registered
        if (snapshot.exists()) {
          setupServices()
          setAdmin(true);
          setPermissions(snapshot.val().permissions)
        //if user wasn't found among registered users  
        } else {
          console.log("No data available at: users/" + user.uid)
          setAdmin(false);
        }
      }).catch((error) => {
        console.error(error)
      });
    //If user is not signed in
    } else {
      setAdmin(false);
    }
  }, [user])

  const setupServices = () => {
    ServiceSetup(get, set, child, dbRef)
  }

  const ChangeRoute = (route, link) => {
    setLoaded(false)

    console.log("1: Changing to route: " + route)

    //If a user has signed in but isn't registered and wants to logout
    if(route == "signout") {
      console.log("Signing out")
      signOut(auth)
      return
    }

    if(link === undefined || null){
      if(page == undefined || route !== page.route) {
        console.log("2: in undefined")
        setPage(pages[route])
      } else {
        //If the user has navigated to same page as is the current, just set loaded to true
        setLoaded(true)
      }
    }
    else {
      console.log("2: in defined")
      GetSetLinkedPage(route,link)
    }

    console.log("3: done")
  }

  const ChangeMode = (adminState) => {
    setAdmin(adminState)
  }

  //When pages are received
  useEffect(() => {
    if (page != null) {
      setLoaded(true)
    }
  }, [page])

  //if there is a user authenticated
  if (user != null) {
    if (admin) {
      return (
        <AdminNavigator auth={auth} user={user} userPermissions={permissions} dbRef={dbRef} />
      )
    } else {
      return (
        <div className="App">
          <HeaderLogout ChangeView={ChangeRoute} />
          <NoAccount user={user} />
        </div>
      )
    }
  } else {
    if (!loaded || page == null) {
      return (
        <div className="App">
          <Header ChangeView={ChangeRoute} />
          <Loading />
        </div>
      )
    } else {
      return (
        <div className="App">
          <Header ChangeView={ChangeRoute} />
          <Page ChangeRoute={ChangeRoute} page={page} auth={auth} dbRef={dbRef} fbget={get} fbchild={child}/>
        </div>
      )
    }
  }
}

export default App;
