import React, { useState, useEffect } from 'react'
import "../containers/Containers.css"
import "../containers/Schemas.css"
import Divider from '../items/Divider.js'

const ScheduleItem = (props) => {
    return (
        <ul className='scheduleitem'>
        <li className='time'>{props.time}</li>
        {
            props.persons.map(person => {
                return(
                    <li className='person'>{person}</li>
                )
            })
        }
        </ul>
    )
}
export default ScheduleItem