import React, { useState, useEffect } from 'react'
import { get } from "firebase/database";

import "../../main/Main.css"
import "../../containers/Containers.css"
import "../../items/Items.css"
import '../items/AdminItems.css'
import Divider from '../../items/Divider'
import AdminItem from '../items/AdminItem';

const AdminMain = (props) => {


    return (
        /* -- page -- */
        <div className='main' id="page" style={{ backgroundImage: `url('https://lh3.googleusercontent.com/fife/AAWUweXlGiPY1rlVpg77_zVAG8wCEAA8IBS3Pfi7_aGTwnL5zRzWABoK22RtEl0YLrEMqpEOgaWnpVqsSA-6HeW7DCtDxrspJ6CTu-soZGXMkg8U-NU_VPJ1badvtptwJ2Ehaccg9afAihJl1kguf0-Z2BYJ9M_vtLdrUWcoSgfe5fcBFnyT5vE8a_oKVw3yp4ynBm8ON3ab8xLsXpOLhj5mGuWxAtnY-xlLierK7LjGpymOzOvVZZINOIDHanPFxQOtW0etV1Am3azYHYgcNRFuxMnL1V18mZWlD3tpo-BmuRcHo6G8moBayqbBZPrMuzpDp7hr8fC3MjORslogVe-AfFBLQHI6kDdcIDBegyErE0t6-Ur--W0rhtNVN3q_JfGNwIW7UuU_5Wwlhh4t8WBpKDTbUrSdmOOihCHkTqx9Ox7FI4H9mfdm3IhKEFn7-idj-SocYGa2u9CFWcR6RPKZKWy9i6bmlAoMoohqniA-JmRPymgtz_Kixw70tjMrGzAC4B8Id_cVd4l5nsdWA9SOaB0QFGu8LhkPaix1uRVBDd_TjZsu0qYSXyhu5M1q4_WcNompwnAoCY-caHfvAWf471dGlIQ5sUZ5rid3jCUWizBIwp2bOWyaxJ3-NdESfiuAKZW1Zu6BjTzR01b9KKV5ax0RnIznwJ2HcriNXHgCz58_jX3mpY2mukzZVcluGgwvrCiznyVM6cjbFwgvflsjdecEA4b0R-VEVpY=w935-h887-ft')` }}>

            {props.userPermissions == null
                ? /* -- Show loading -- */ <h1>Laddar</h1> 
                : /* -- Show Container -- */
                <div className="container admin">
                    <h1>Administrera sidan</h1>
                    <Divider />
                    <ul className="admin-items">
                        {//permission 1 is =
                            props.userPermissions[1] == true && <h1>1 is true</h1>}
                        {//permission 2 is =
                            props.userPermissions[2] == true && <h1>2 is true</h1>}
                        {//permission 3 is = NYHETER
                            props.userPermissions[3] == true && <AdminItem caption="Nyheter" onClick={props.ChangeView}/>}
                        {//permission 4 is = EVENTS
                            props.userPermissions[4] == true && <AdminItem caption="Aktiviteter" onClick={props.ChangeView}/>}
                        {//permission 5 is =
                            props.userPermissions[5] == true && <h1>5 is true</h1>}
                        {//permission 6 is =
                            props.userPermissions[6] == true && <h1>6 is true</h1>}
                        {//permission 7 is =
                            props.userPermissions[7] == true && <h1>7 is true</h1>}
                        {//permission 8 is =
                            props.userPermissions[8] == true && <h1>8 is true</h1>}
                        {//permission 9 is = ADMIN
                            props.userPermissions[9] == true && <h1>9 is true</h1>}
                    </ul>
                </div>
            }

        </div>
    )
}
export default AdminMain
