import React, { useState, useEffect } from 'react'


const InputNew = (props) => {

    //type is item or container
    if(props.type == "item") {
        return(
            <div className="input-new-item" onClick={ () => props.onClick(props.type)}>
                <p id="add">+</p>
                <p id="add-text">Lägg till nytt item</p>
            </div>
    )
    } else if (props.type == "container") {
        return(
            <div className="container admin input-new-container" onClick={ () => props.onClick(props.type)}>
                <p id="add">+</p>
                <p id="add-text">Lägg till ny container</p>
            </div>
    ) 
    } else if (props.type == "post") {
        return (
        <div className="container admin input-new-post" onClick={ () => props.onClick(props.type)}>
                <p id="add-text">Spara och ladda upp!</p>
        </div>
    )
    } else {
        return <h1>ERROR CREATING NEW ADDINPUT. WRONG TYPE</h1>
    }
    
    
}
export default InputNew