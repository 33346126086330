import React from "react";

const Loading = () => {

    return (
        <div className="main" id="page">
            <div className="container">
                <div id="loading">
                    <h1>LOADING</h1>
                </div>
            </div>
        </div>
    )

}

export default Loading