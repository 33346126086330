import React, { useState, useEffect } from 'react'
import Divider from '../../items/Divider'

const NoAccount = (props) => {

    return (
        <div className='main' id="page" style={{ backgroundImage: `url('https://lh3.googleusercontent.com/fife/AAWUweXlGiPY1rlVpg77_zVAG8wCEAA8IBS3Pfi7_aGTwnL5zRzWABoK22RtEl0YLrEMqpEOgaWnpVqsSA-6HeW7DCtDxrspJ6CTu-soZGXMkg8U-NU_VPJ1badvtptwJ2Ehaccg9afAihJl1kguf0-Z2BYJ9M_vtLdrUWcoSgfe5fcBFnyT5vE8a_oKVw3yp4ynBm8ON3ab8xLsXpOLhj5mGuWxAtnY-xlLierK7LjGpymOzOvVZZINOIDHanPFxQOtW0etV1Am3azYHYgcNRFuxMnL1V18mZWlD3tpo-BmuRcHo6G8moBayqbBZPrMuzpDp7hr8fC3MjORslogVe-AfFBLQHI6kDdcIDBegyErE0t6-Ur--W0rhtNVN3q_JfGNwIW7UuU_5Wwlhh4t8WBpKDTbUrSdmOOihCHkTqx9Ox7FI4H9mfdm3IhKEFn7-idj-SocYGa2u9CFWcR6RPKZKWy9i6bmlAoMoohqniA-JmRPymgtz_Kixw70tjMrGzAC4B8Id_cVd4l5nsdWA9SOaB0QFGu8LhkPaix1uRVBDd_TjZsu0qYSXyhu5M1q4_WcNompwnAoCY-caHfvAWf471dGlIQ5sUZ5rid3jCUWizBIwp2bOWyaxJ3-NdESfiuAKZW1Zu6BjTzR01b9KKV5ax0RnIznwJ2HcriNXHgCz58_jX3mpY2mukzZVcluGgwvrCiznyVM6cjbFwgvflsjdecEA4b0R-VEVpY=w935-h887-ft')` }}>
            <div className="container">
                <h1>Fel vid inloggning</h1>
                <Divider />
                <ul className="items">
                    <div className="item-text">
                        <h4>Du har inget konto registrerat här</h4>
                        <div>
                        <p>Google-kontot ({props.user.email}) har inget JSS konto kopplat till sig.</p>
                        <p>Änsålänge är JSS e-tjänster endast avsedda för styrelsearbete</p>
                        <p>Är du med i styrelsen och har problem med att komma åt sidan kontakta Webbansvarig</p>
                        </div>
                    </div>
                </ul>
            </div>
        </div>

    )
}
export default NoAccount