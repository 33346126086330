import React, { useState, useEffect } from 'react'
import "./main/Main.css"

import Events from './containers/Events.js'
import News from './containers/News.js'
import TimeSchedule from './containers/TimeSchedule'
import Container from './containers/Container.js'
import Loading from './Loading'


const Page = (props) => {
    //props.ChangeView, props.page

    /*document.documentElement.style.setProperty('--background-image', props.page.image); */

    const [loaded, setLoaded] = useState(false)
    const [linkedContainers, setLinkedContainers] = useState([])
    const [containers, setContainers] = useState([])



    //setting state on initial not every re render
    useEffect(() => {
        console.log("PAGE SETUP CALLED")
        setContainers(props.page.containers)
        if (GotLinkedContainers()) {
            GetLinkedContainer(props.page.linkedContainers, props.page.route)
        }
    }, [])

    const GotLinkedContainers = () => {
        if (props.page.linkedContainers === undefined || null) {
            return false;
        } else {
            return true
        }
    }


    //Fetch a specified linked page
    const GetLinkedContainer = (link, route) => {
        props.fbget(props.fbchild(props.dbRef, link + `/` + route)).then((snapshot) => {
            if (snapshot.exists()) {
                console.log(snapshot.val())
                setLinkedContainers(snapshot.val())
            } else {
                console.log("No data available at")
                console.log(link + " / " + route)
            }
        }).catch((error) => {
            console.log("Error while fetching: " + link + " " + route)
            console.error(error)
        });
    }

    //set Loaded when containers have been mapped
    useEffect(() => {
        console.log(containers)
        if (containers != undefined && containers.length > 0) setLoaded(true)
    }, [containers])

    //Update rendered containers with the linked containers
    useEffect(() => {
        setLoaded(false)
        console.log(linkedContainers)
        //Vilken av dessa ska det vara?
        if (linkedContainers.length > 0) {
            setContainers([...containers, ...linkedContainers])
            //linkedContainers.forEach(container => setContainers([...containers, container]))
        }

        console.log(containers)

    }, [linkedContainers])

    if (!loaded) {
        return (<Loading />)
    } else {
        return (
            <div className="main" id="page" style={{ backgroundImage: `url(${props.page.image})` }}>
                {
                    containers.map((container, index) => {
                        return <Container key={index}
                            container={container}
                            caption={container.caption}
                            style={container.style}
                            listType={container.listType}
                            items={container.items}
                            ChangeRoute={props.ChangeRoute}
                            auth={props.auth} />
                    })
                }
            </div>
        )
    }
}
export default Page