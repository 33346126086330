import React, { useState, useEffect } from 'react'
import parse from 'html-react-parser';

import "../containers/Containers.css"
import Divider from './Divider.js'

const TextItem = (props) => {
    //props.caption, props.content, props.image
    return (
        <div className="item-text">
        <h4>{props.caption}</h4>
        <p>{parse(props.content)}</p>
        </div>
        )
}
export default TextItem