import React, { useState, useEffect } from 'react'
import parse from 'html-react-parser';

import "../containers/Containers.css"
import Divider from './Divider.js'

const ImageItem = (props) => {
    //props.type, props.image
    return (
        <div className={props.type}>
            <img src={props.image} alt='Image' /> 
        </div>
        )
}
export default ImageItem