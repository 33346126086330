import React, { useState, useEffect } from 'react'
import { get } from "firebase/database";

import "../../main/Main.css"
import "../../containers/Containers.css"
import "../../items/Items.css"
import '../items/AdminItems.css'
import Divider from '../../items/Divider'
import AdminItem from '../items/AdminItem'

const AdminServices = (props) => {


    return (
        /* -- page -- */
        <div className='main' id="page" style={{ backgroundImage: `url('https://lh3.googleusercontent.com/fife/AAWUweXlGiPY1rlVpg77_zVAG8wCEAA8IBS3Pfi7_aGTwnL5zRzWABoK22RtEl0YLrEMqpEOgaWnpVqsSA-6HeW7DCtDxrspJ6CTu-soZGXMkg8U-NU_VPJ1badvtptwJ2Ehaccg9afAihJl1kguf0-Z2BYJ9M_vtLdrUWcoSgfe5fcBFnyT5vE8a_oKVw3yp4ynBm8ON3ab8xLsXpOLhj5mGuWxAtnY-xlLierK7LjGpymOzOvVZZINOIDHanPFxQOtW0etV1Am3azYHYgcNRFuxMnL1V18mZWlD3tpo-BmuRcHo6G8moBayqbBZPrMuzpDp7hr8fC3MjORslogVe-AfFBLQHI6kDdcIDBegyErE0t6-Ur--W0rhtNVN3q_JfGNwIW7UuU_5Wwlhh4t8WBpKDTbUrSdmOOihCHkTqx9Ox7FI4H9mfdm3IhKEFn7-idj-SocYGa2u9CFWcR6RPKZKWy9i6bmlAoMoohqniA-JmRPymgtz_Kixw70tjMrGzAC4B8Id_cVd4l5nsdWA9SOaB0QFGu8LhkPaix1uRVBDd_TjZsu0qYSXyhu5M1q4_WcNompwnAoCY-caHfvAWf471dGlIQ5sUZ5rid3jCUWizBIwp2bOWyaxJ3-NdESfiuAKZW1Zu6BjTzR01b9KKV5ax0RnIznwJ2HcriNXHgCz58_jX3mpY2mukzZVcluGgwvrCiznyVM6cjbFwgvflsjdecEA4b0R-VEVpY=w935-h887-ft')` }}>

            {props.userPermissions == null
                ? /* -- Show loading -- */ <h1>Laddar</h1> 
                : /* -- Show Container -- */
                <div className="container admin">
                    <h1>Administrera sidan</h1>
                    <Divider />
                    <ul className="admin-items">
                        
                        { //ADMIN NEWS MANAGEMENT
                            "events" in props.userPermissions && (
                                console.log("Found news as a permission " + props.userPermissions.news.create + " " + props.userPermissions.news.update + " " + props.userPermissions.news.delete),
                                <>
                                <h1 className='admin-items-header'>Aktiviteter</h1>
                                <ul className='admin-items-row'>
                                <AdminItem caption="Sjösättning" active={props.userPermissions.events.sjosattning} ChangeRoute={props.ChangeRoute}/>
                                <AdminItem caption="Torrsättning" active={props.userPermissions.events.upptagning} ChangeRoute={props.ChangeRoute}/>
                                <AdminItem caption="aktivera aktivitet" active={props.userPermissions.events.activate} ChangeRoute={props.ChangeRoute}/>
                                </ul>
                                <ul className='admin-items-row'>
                                <AdminItem caption="lägg till aktivitet" active={props.userPermissions.events.create} ChangeRoute={props.ChangeRoute}/>
                                <AdminItem caption="ändra aktivitet" active={props.userPermissions.events.update} ChangeRoute={props.ChangeRoute}/>
                                <AdminItem caption="ta bort aktivitet" active={props.userPermissions.events.delete} ChangeRoute={props.ChangeRoute}/>
                                </ul>
                                <Divider />
                                </>
                            )
                        }
                        
                        <h1 className='admin-items-header'>Nyheter</h1>
                        { //ADMIN NEWS MANAGEMENT
                            "news" in props.userPermissions && (
                                console.log("Found news as a permission " + props.userPermissions.news.create + " " + props.userPermissions.news.update + " " + props.userPermissions.news.delete),
                                <ul className='admin-items-row'>
                                <AdminItem caption="lägg till nyhet" route="service_add_news" active={props.userPermissions.news.create} ChangeRoute={props.ChangeRoute}/>
                                <AdminItem caption="ändra nyhet" active={props.userPermissions.news.update} ChangeRoute={props.ChangeRoute}/>
                                <AdminItem caption="ta bort nyhet" active={props.userPermissions.news.delete} ChangeRoute={props.ChangeRoute}/>
                                </ul>
                            )
                        }
                        <Divider />
                        </ul>
                </div>
            }

        </div>
    )
}
export default AdminServices
