import React, { useState, useEffect } from 'react'

import Divider from '../../../items/Divider'
import InputContainerTitle from './InputContainerTitle'
import InputNew from './InputNew'
import NewItem from './NewItem'

const NewContainer = (props) => {

    let [caption, setCaption] = useState()
    let [items, setItems] = useState([])

    const changeCaption = (event) => {
        setCaption(event.target.value)
    }

    useEffect( () => {
        props.updateContainer("items", items, props.index)
    }, [items])

    useEffect( () => {
        props.updateContainer("caption", caption, props.index)
    }, [caption])

    const newItem = () => {
        console.log("ADDING NEW ITEM")
        setItems([...items, {
            "caption": null,
            "content": null,
            "image": null,
            "type": null
        }])    
    }

    const updateItemChanged = (key, value, index) => {

        let newArr = [...items]; // copy the old items array
        
        newArr[index][key] = value; // replace value at index

        //When type is changed
        if(key == "type") {
            //If the type is image-half, add 2 images
            if(value == "image-half") {
                newArr.splice(index, 0, newArr[index]) //adds the new half-image next to the index, not last
                newArr.join()
            }
        }
        setItems(newArr); //update state with the new array
    }

    return(
            <div className="container admin">
                <input type="text" className="input-container-title" placeholder='ange container titel... ' onChange={changeCaption}/>
                <Divider />
                <ul className='new-items-list'>
                    {
                       items.map( (item, index) => {
                        return <NewItem key={index} index={index} type={item.type} caption={item.caption} content={item.content} image={item.image} updateItem={updateItemChanged} />
                       })
                    }
                </ul>
                <InputNew type="item" onClick={newItem} />
            </div>
    )
    
}
export default NewContainer