import React, { useState, useEffect } from 'react'
import "../../main/Main.css"
import "../../containers/Containers.css"
import "./Login.css"

import Divider from '../../items/Divider'

/* -- Auth stuff -- */
import { GoogleAuthProvider, getAuth, signInWithRedirect, getRedirectResult, onAuthStateChanged, signOut } from "firebase/auth";

const provider = new GoogleAuthProvider();


const SignIn = (props) => {
    //props.firebase, props.auth

    const signInWithGoogle = () => {
        signInWithRedirect(props.auth, provider);
    }

    const signOutFromGoogle = () => {
        signOut(props.auth)
    }

    return (
                <ul className="items">
                    <div className="item-login"
                        onClick={signInWithGoogle}>
                        <div className="google-icon-wrapper" >
                            <img className="google-icon" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg" alt="image"/>
                        </div>
                        <p className="google-text">Logga in med google</p>
                    </div>
                </ul>

    )
}
export default SignIn