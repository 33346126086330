import React, { useState, useEffect } from 'react'

import HeaderItem from "../main/HeaderItem"


const AdminHeader = (props) => {
    return(
            <div className='header admin-header'>
                <div className='header-items-container admin-header-items-container'>
                <HeaderItem caption={"Styrelsen"} size={"small"} route={"main"} admin={true} ChangeView={props.ChangeView} />
                <HeaderItem caption={"Administrera"} size={"small"} route={"services"} admin={true} ChangeView={props.ChangeView} />
                <HeaderItem caption={"Logga ut"} size={"large"} route={"signout"} admin={true} ChangeView={props.ChangeView} />
                <HeaderItem caption={"E-tjänster"} size={"small"} route={"services"} admin={true} ChangeView={props.ChangeView} />
                <HeaderItem caption={""} size={"small"} route={"login"} admin={true} ChangeView={props.ChangeView} />
                </div>
            </div>
           )
}
export default AdminHeader