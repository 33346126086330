import React, { useState, useEffect } from 'react'
import { signOut } from "firebase/auth";

import './Admin.css'
import "./services/inputs.css"
import "./services/services.css"
import AdminHeader from './AdminHeader'
import AdminMain from './pages/AdminMain'
import AdminServices from './pages/AdminServices';

import AddNews from './services/news/AddNews';

const AdminNavigator = (props) => {
    //props.auth props.user props.userPermissions props.dbRef

    const [view, setView] = useState("default")

    const ChangeRoute = (newView) => {
        if(newView == "signout") {
            signOut(props.auth)
        } else{
            
        }
        setView(newView)
    }

    return (
        <div className="App">
            <AdminHeader ChangeView={ChangeRoute} />
                {(() => {
                    switch (view) {
                        case "administrate": {
                            return (
                                <AdminMain auth={props.auth} user={props.user} userPermissions={props.userPermissions} dbRef={props.dbRef} ChangeRoute={ChangeRoute}/>
                            )
                        }
                        case "services": {
                            return (
                                <AdminServices auth={props.auth} user={props.user} userPermissions={props.userPermissions} dbRef={props.dbRef} ChangeRoute={ChangeRoute}/>
                            )
                        }
                        case "service_add_news": {
                            return (
                                <AddNews />
                            )
                        }
                        default: {
                            return (
                                <h1>test</h1>
                            )
                        }
                    }
                })()}    
        </div>
    )
}
export default AdminNavigator
